<template>
  <div>
    <div>
      <img :src="`${$staticUrl}/a1.png`" />
    </div>
    <div class="about_content">
      <div class="about_left">
        <div :class="['about_left_item',active == i?'actived':'']" v-for="(v, i) in data1" :key="i" @click="setcItem(i)">
          {{v}}
        </div>
      </div>
      <div class="about_right">
<!--        <img :src="cSrc" />-->
        <Detail :panel="active"></Detail>
      </div>
    </div>
  </div>
</template>

<script>
import Detail from './detail.vue'
export default {
  components:{
    Detail
  },
  props:{},
  data() {
    return {
      active:'company',
      cSrc:`${this.$staticUrl}/a2.png`,
      data1:{
        company: '公司介绍',
        teamwork: '团队介绍',
        culture: '企业文化',
        contact: '联系我们'
      }
    }
  },
  methods: {
    setcItem(val) {
      this.active = val
    },
    // setcItem(val){
    //   let dom = document.getElementsByClassName('about_right')[0]
    //   dom.className+='  animate__animated animate__pulse'
    //   setTimeout(()=>{
    //     dom.className='about_right'
    //   },1000)
    //   this.cItem=val
    //   switch (val) {
    //     case '公司介绍':
    //       this.cSrc=`${this.$staticUrl}/a2.png`
    //       break;
    //     case '团队介绍':
    //       this.cSrc=`${this.$staticUrl}/a5.png`
    //       break;
    //     case '企业文化':
    //       this.cSrc=`${this.$staticUrl}/a3.png`
    //       break;
    //     case '联系我们':
    //       this.cSrc=`${this.$staticUrl}/a4.png`
    //       break;
    //     default:
    //       this.cSrc=`${this.$staticUrl}/a2.png`
    //       break;
    //   }
    // }

  },
  mounted() {
    let dom = document.getElementsByClassName('about_right')[0]
    dom.className+='  animate__animated animate__pulse'
    setTimeout(()=>{
      dom.className='about_right'
    },1000)
  },
}
</script>
<style lang='less' scoped>
.about_content{
  width:100%;
  padding:24px 390px;
  display: flex;
  gap:16px;
}
.about_left{
  flex: 1;
  .about_left_item{
    width:272px;
    height: 52px;
    font-size: 16px;
    text-align: center;
    line-height: 52px;
    cursor: pointer;
    font-weight: 500;
  }
  .actived{
    background: #1D65E3;
    color:#fff;
  }
}
.about_right{
  flex: 3;
}
</style>