<template>
  <div class="detail-container">
    <!--    公司介绍-->
    <div class="panel company" v-show="panel === 'company'">
      <h1 class="panel-title">广东海鸥飞行汽车集团有限公司</h1>
      <p>海鸥飞行汽车集团，专业从事飞行汽车产业生态链的建设和开发，其奋斗目标是全球500强企业。</p>
      <p>
        海鸥飞行汽车集团在珠海设有珠海海鸥信息科技有限公司(以下简称“海鸥科技”)高科技企业，专业从事飞行汽车、飞行监控指挥服务系统、飞行运营服务等业务的开发。海鸥科技现拥有“珠海霄鹰科技发展有限公司"、“珠海海鸥航空电子系统有限公司”和“广东海鸥通用航空有限公司”三家全资子公司。</p>
      <p>海鸥科技的发展愿景是提供“交通运输革命性变革”的飞行汽车技术产品，向市场提供”能跑 -
        会飞的飞行汽车，向行业提供飞行监控指挥服务系统，积极参与中国飞行汽车生态链的建设和发展!</p>
      <p>
        海鸥科技业务范围涵盖：飞行汽车、飞行监控指挥服务系统、航电系统、自动驾驶系统、三维视景导航地图系统、动力电池、航空发动机等；并提供支持航空运营的配套服务：技术咨询和服务、航空运输、民用航空器维修、培训及零部件设计和生产、飞行员培训等支持航空运营的配套服务；旨在打造国际顶尖的飞行汽车研制、生产、服务公司。</p>
      <p>
        海鸥科技注重核心技术的创新和研发，围绕着EAGLE系列飞行汽车及其配套技术，积极创新，截止2022年12月，已获批42项知识产权，包括智能飞控系统技术、智能监控指挥系统技术、快速分离组合技术等多项发明专利以及外观设计专利等。</p>
      <p>
        海鸥科技具备良好的自主发展战略规划，组建了强大的专家精英团队，掌握自主核心技术，未来将实现飞行汽车的规模化生产和营运，向国内及全球提供飞行汽车技术产品及服务。</p>
    </div>
    <!--    团队介绍-->
    <div class="panel teamwork" v-show="panel === 'teamwork'">
      <h1 class="panel-title">海鸥飞行汽车团队</h1>
      <p>
        以创始人颜军院士领衔的3名院士为核心，召集了国内外数十名航空业内资深科技专家成员，其中国内外院士3名、教授4名、国家特聘专家4名、博士后3名，博士8名，高级工程师7名。技术业务领域涵盖航电系统、数据链系统、飞参系统、飞行控制系统、三维视景导航系统、自主驾驶飞行系统、飞行监控指挥系统、航空发动机、飞行器设计等。</p>
    </div>
    <!--    企业文化-->
    <div class="panel culture" v-show="panel === 'culture'">
      <h1 class="panel-title">公司愿景</h1>
      <p>智造“能跑会飞”的飞行汽车，引领中国飞行汽车生态发展！</p>
<!--      <img :src="`${$staticUrl}/panel-culture-pic1.png`"/>-->
      <img src="@/assets/panel-culture-pic1.png"/>
      <h1 class="panel-title">核心价值</h1>
      <p>提供“交通运输革命性变革”的技术产品！</p>
      <img src="@/assets/panel-culture-pic2.png"/>
<!--      <img :src="`${$staticUrl}/panel-culture-pic2.png`"/>-->
      <h1 class="panel-title">追逐目标</h1>
      <p class="no-indent">构建飞行汽车的全产业链</p>
      <strong>“能跑会飞”技术</strong>
      <p class="no-indent">飞行与地面行驶自由切换技术；机体具备轻质化结构，优化气动性能设计；为提高航时性能和载荷能力提供基础</p>
      <strong>智能自主化飞行</strong>
      <p class="no-indent">智能自主化飞行是飞行汽车的发展方向和趋势之一</p>
      <strong>安全飞行保障</strong>
      <p class="no-indent">为支撑安全飞行和智能飞行，需要对飞行汽车的飞行过程进行地面监控指挥服务</p>
      <strong>高效能源技术</strong>
      <p class="no-indent">采用清洁能源，锂电池、氢燃料电池等新型高效率、高可靠、适用范围广能源系统</p>
    </div>
    <!--    联系我们-->
    <div class="panel contact" v-show="panel === 'contact'">
      <h1 class="panel-title">电子邮箱</h1>
      <p class="no-indent">seagull_flyingcar@163.com</p>
      <h1 class="panel-title">联系电话</h1>
      <p class="no-indent">0756-2210095</p>
      <h1 class="panel-title">官方网站</h1>
      <p class="no-indent">www.seagull-flyingcar.com</p>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    panel: {
      type: String,
      default: ''
    }
  },
  data() {
  },
}
</script>
<style lang="less" scoped>
.panel-title {
  color: 24px;
}

.panel > p {
  color: rgba(51, 51, 51, 0.70);
  font-size: 16px;
  text-indent: 2em;
  &.no-indent {
    text-indent: 0;
  }
}
.panel > strong {
  color: #1D65E3;
  font-size: 16px;
}
</style>